<template>
    <div class="p-4 pt-10 md:pt-16">
        <div class="bg-white border border-innerBorder rounded-2xl p-4 lg:p-8 md:w-2/3 xl:w-1/2 mx-auto">
            <p class="font-bold text-2xl mb-2">Création de compte</p>
            <p class="text-fonctGrey mb-6">Un code de vérification a été envoyé à {{email}}</p>
            <VerifyForm/>  
        </div>
        <div class="my-4 px-2 font-bold text-sm sm:text-base text-center">
            <p>Vous n’avez pas reçu de code ?</p>
            <p @click="resendCode" class="text-blueLink underline cursor-pointer">Renvoyer le code</p>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import VerifyForm from './forms/VerifyForm'
export default {
    data() {
        return {
            
        }
    },
    components: {
        VerifyForm
    },
    computed: {
        email(){
            return this.$store.getters.getRegisterUsername
        }
    },
    methods: {
        resendCode(){
            axios.post('/users/send-otp',{
                username: this.email
            })
            .then( res => {
                console.log(res)
            })
            .catch( err => {
                console.log(err.response)
            })
        }
    },
    
}
</script>

<style scoped>

</style>